<template>
  <div
    v-if="showSearchPopup"
    class="absolute w-full h-full top-0 left-0 bg-black bg-opacity-70 flex items-center justify-center"
    style="z-index: 1"
  >
    <div
      class="search-popup"
      ref="popup"
      :class="{ in: opening, out: closing }"
    >
      <div class="search-input">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-7 h-7 mx-1"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
        <input
          ref="search"
          placeholder="Ricerca Bar o Regione"
          class="h-full w-full bg-gray-900 m-1 px-4 rounded-full"
          type="text"
          v-model="searchQuery"
          @keyup.enter="enterFirstResult"
        />
        <button class="px-2 m-1 text-2xl md:text-3xl" @click="close">
          &times;
        </button>
      </div>
      <div class="search-results">
        <div class="room-link py-6" v-if="filteredRooms.length == 0">
          <p>No results found</p>
        </div>
        <div v-else>
          <button
            @click="goToBarHandler(bar.name)"
            class="room-link"
            v-for="(bar, index) in filteredRooms"
            :key="index"
          >
            <div class="flex justify-center" style="width: 20%">
              <img
                class="rounded-full h-14 w-14 p-1 object-cover"
                :src="
                  bar.image ? bar.image : '/pwa/images/icons/icon-192x192.png'
                "
                :alt="bar.visibleName"
              />
            </div>
            <div
              class="flex items-center justify-between px-4"
              style="width: 80%"
            >
              <div
                class="flex items-center justify-between space-x-4 flex-1 min-w-0"
              >
                <h2
                  class="text-center uppercase font-bold ml-2 md:text-xl truncate"
                  :class="bar.cashback > 0 ? 'text-primary' : 'text-secondary'"
                >
                  {{ bar.visibleName ? bar.visibleName : "-" }}
                </h2>
                <p
                  v-if="!isMobile"
                  class="text-xs bg-gray-600 py-1 px-2 rounded flex justify-center text-center opacity-60"
                >
                  {{ bar.region }}
                </p>
              </div>
              <div
                class="flex items-center justify-between flex-col"
                v-if="!hideJackpot"
              >
                <div id="bars-jackpots">
                  <p class="font-bold">
                    {{ bar.jackpot ? bar.jackpot : 0 | money }}
                  </p>
                  <p class="uppercase text-xs" style="margin-top: -0.2rem">
                    Jackpot da Bar
                  </p>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>

      <div class="bg-gray-900 rounded-b-lg p-4 flex justify-between">
        <p v-show="!isMobile" class="text-xs text-gray-400">
          Close with <span class="border rounded p-0.5 ml-1">ESC</span>
        </p>
        <p class="text-xs text-gray-400">
          {{ filteredRooms.length }} Result{{
            filteredRooms.length > 1 ? "s" : ""
          }}
          Found
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rooms: {
      type: Array,
      default: [],
    },
    showSearchPopup: {
      type: Boolean,
      default: false,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchQuery: "",
      hideJackpot: process.env.VUE_APP_HIDE_JACKPOTS,
      opening: true,
      closing: false,
    };
  },

  computed: {
    filteredRooms() {
      if (this.rooms && !!this.rooms.length) {
        return this.rooms.filter(
          (room) =>
            room.visibleName
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase()) ||
            room.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            room.region.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
      return [];
    },
  },

  watch: {
    showSearchPopup: function (val) {
      if (val) {
        this.$nextTick(() => {
          this.$refs.search.focus();
        });
        setTimeout(() => {
          this.opening = false;
        }, 300);
      }
    },
  },

  mounted() {
    window.addEventListener("keydown", this.handleKeydown);
  },

  methods: {
    enterFirstResult() {
      if (this.filteredRooms.length === 1) {
        this.goToBarHandler(this.filteredRooms[0].name);
      }
    },

    goToBarHandler(barName) {
      this.close();
      this.$router.push({ name: "Bar", params: { barName: barName } });
    },

    close() {
      this.closing = true;
      this.searchQuery = "";
      setTimeout(() => {
        this.closing = false;
        this.$emit("closeSearchModal");
        this.$root.$emit("closeMenu");
      }, 150);
    },

    handleKeydown(event) {
      if (event.ctrlKey && (event.key === "k" || event.key === "K")) {
        this.$emit("updateShowSearchPopup", true);
        event.preventDefault();
        // this.$nextTick(() => {
        //   this.$refs.search.focus();
        // });
      }
      if (event.keyCode === 27) {
        this.close();
      }
    },
  },

  beforeDestroy() {
    window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style scoped lang="postcss">
.search-popup {
  @apply bg-gray-900 rounded-lg border border-gray-600 h-full w-full flex flex-col;
  /* top: 2%; */
  /* @screen md {
    top: 15%;
  }
  left: 50%;
  transform: translateX(-50%); */
  @screen lg {
    @apply w-1/2 h-fit block mb-2;
  }
}

.search-input {
  width: calc(100% - 0.5rem);
  @apply flex bg-gray-900 rounded-lg my-1 mx-2 py-1 items-center;
}

#bars-jackpots {
  @apply flex flex-col items-center gap-1;
  @screen xs {
    margin-top: -0.2rem;
  }
}

.search-results {
  @apply relative flex-1 overflow-y-auto overflow-x-hidden items-center py-1 bg-gray-900;

  @screen lg {
    max-height: 500px;
  }
}

.room-link {
  @apply w-full flex items-center justify-center border border-gray-700 bg-gray-800 text-white mb-2 transform;
}

.in {
  animation: openModal 0.1s ease-in forwards;
}

.out {
  animation: closeModal 0.1s ease-out forwards;
}
</style>
