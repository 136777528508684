var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isRegionReady,
          expression: "isRegionReady",
        },
      ],
      staticClass: "h-full w-full flex items-center justify-center",
    },
    [
      _c("div", {
        ref: "mapRegion",
        staticClass: "region-map",
        attrs: { id: _vm.regionName },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }