var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showSearchPopup
    ? _c(
        "div",
        {
          staticClass:
            "absolute w-full h-full top-0 left-0 bg-black bg-opacity-70 flex items-center justify-center",
          staticStyle: { "z-index": "1" },
        },
        [
          _c(
            "div",
            {
              ref: "popup",
              staticClass: "search-popup",
              class: { in: _vm.opening, out: _vm.closing },
            },
            [
              _c("div", { staticClass: "search-input" }, [
                _c(
                  "svg",
                  {
                    staticClass: "w-7 h-7 mx-1",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      fill: "none",
                      viewBox: "0 0 24 24",
                      "stroke-width": "1.5",
                      stroke: "currentColor",
                    },
                  },
                  [
                    _c("path", {
                      attrs: {
                        "stroke-linecap": "round",
                        "stroke-linejoin": "round",
                        d: "M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z",
                      },
                    }),
                  ]
                ),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.searchQuery,
                      expression: "searchQuery",
                    },
                  ],
                  ref: "search",
                  staticClass:
                    "h-full w-full bg-gray-900 m-1 px-4 rounded-full",
                  attrs: { placeholder: "Ricerca Bar o Regione", type: "text" },
                  domProps: { value: _vm.searchQuery },
                  on: {
                    keyup: function ($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.enterFirstResult.apply(null, arguments)
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.searchQuery = $event.target.value
                    },
                  },
                }),
                _c(
                  "button",
                  {
                    staticClass: "px-2 m-1 text-2xl md:text-3xl",
                    on: { click: _vm.close },
                  },
                  [_vm._v(" × ")]
                ),
              ]),
              _c("div", { staticClass: "search-results" }, [
                _vm.filteredRooms.length == 0
                  ? _c("div", { staticClass: "room-link py-6" }, [
                      _c("p", [_vm._v("No results found")]),
                    ])
                  : _c(
                      "div",
                      _vm._l(_vm.filteredRooms, function (bar, index) {
                        return _c(
                          "button",
                          {
                            key: index,
                            staticClass: "room-link",
                            on: {
                              click: function ($event) {
                                return _vm.goToBarHandler(bar.name)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "flex justify-center",
                                staticStyle: { width: "20%" },
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "rounded-full h-14 w-14 p-1 object-cover",
                                  attrs: {
                                    src: bar.image
                                      ? bar.image
                                      : "/pwa/images/icons/icon-192x192.png",
                                    alt: bar.visibleName,
                                  },
                                }),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "flex items-center justify-between px-4",
                                staticStyle: { width: "80%" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex items-center justify-between space-x-4 flex-1 min-w-0",
                                  },
                                  [
                                    _c(
                                      "h2",
                                      {
                                        staticClass:
                                          "text-center uppercase font-bold ml-2 md:text-xl truncate",
                                        class:
                                          bar.cashback > 0
                                            ? "text-primary"
                                            : "text-secondary",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              bar.visibleName
                                                ? bar.visibleName
                                                : "-"
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    !_vm.isMobile
                                      ? _c(
                                          "p",
                                          {
                                            staticClass:
                                              "text-xs bg-gray-600 py-1 px-2 rounded flex justify-center text-center opacity-60",
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(bar.region) + " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                !_vm.hideJackpot
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex items-center justify-between flex-col",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { attrs: { id: "bars-jackpots" } },
                                          [
                                            _c(
                                              "p",
                                              { staticClass: "font-bold" },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm._f("money")(
                                                        bar.jackpot
                                                          ? bar.jackpot
                                                          : 0
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "uppercase text-xs",
                                                staticStyle: {
                                                  "margin-top": "-0.2rem",
                                                },
                                              },
                                              [_vm._v(" Jackpot da Bar ")]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "bg-gray-900 rounded-b-lg p-4 flex justify-between",
                },
                [
                  _c(
                    "p",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.isMobile,
                          expression: "!isMobile",
                        },
                      ],
                      staticClass: "text-xs text-gray-400",
                    },
                    [
                      _vm._v(" Close with "),
                      _c("span", { staticClass: "border rounded p-0.5 ml-1" }, [
                        _vm._v("ESC"),
                      ]),
                    ]
                  ),
                  _c("p", { staticClass: "text-xs text-gray-400" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.filteredRooms.length) +
                        " Result" +
                        _vm._s(_vm.filteredRooms.length > 1 ? "s" : "") +
                        " Found "
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }