<template>
  <div
    class="h-full w-full flex items-center justify-center"
    v-show="isRegionReady"
  >
    <div :id="regionName" class="region-map" ref="mapRegion"></div>
  </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5geodata_lang_IT from "@amcharts/amcharts5-geodata/lang/IT";
import italyMap from "@/helpers/italyMap";
import clone from "clone";
import { mapController } from "@/helpers/mapController";
import barsRepository from "@/api/barsRepository";

export default {
  name: "Region",

  props: {
    regionIndex: {
      required: true,
    },

    regionBars: {
      type: Array,
      default: [],
    },

    isRegionReady: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      map: null,
      chart: null,
    };
  },

  computed: {
    regionName() {
      return italyMap.features[this.regionIndex].properties.name;
    },
    region() {
      let map = clone(italyMap);
      map.features = [map.features[this.regionIndex]];
      /* REMOVED THE LOGO AS ASKED, I DONT KNOW WHAT CAN HAPPEN SINCE WE DONT PAY.........*/
      this.map._logo.dispose();
      return map;
    },
  },

  watch: {
    regionBars: function (val) {
      if (val.length > 0 && this.map && this.chart) {
        console.log("watch - regionBars");
        mapController.addRegionPins(
          this.map,
          this.chart,
          val.flat(),
          this.regionName
        );
      }
    },
  },

  mounted() {
    console.log("REGION :: Mounted hook called");
    //  this.getBarsByRegion(this.regionName);

    this.map = am5.Root.new(this.regionName, { useSafeResolution: false });
    /* REMOVED THE LOGO AS ASKED, I DONT KNOW WHAT CAN HAPPEN SINCE WE DONT PAY.........*/
    this.map._logo.dispose();

    // let map = this.map;
    this.map.setThemes([am5themes_Animated.new(this.map)]);

    this.map.geodataNames = am5geodata_lang_IT;
    this.map.geodata = this.region;
    console.log("geo data for region:", this.regionName);
    console.log(this.region);
    this.map.projection = new am5map.geoMercator();
    this.chart = this.map.container.children.push(
      am5map.MapChart.new(this.map, {})
    );
    let polygonSeries = this.chart.series.push(
      am5map.MapPolygonSeries.new(this.map, {
        geoJSON: this.region,
        exclude: ["FR-H", "MT", "SM", "VA"],
        fill: am5.color("#454545"),
      })
    );
    polygonSeries.useGeodata = true;
    this.map.series = polygonSeries;

    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.setAll({
      stroke: am5.color("#454545"),
      strokeWidth: 0.5,
    });
  },

  destroyed() {
    console.log("--------- destroyed ------");
    this.map.dispose();
  },
};
</script>

<style lang="postcss" scoped>
.region-map {
  @apply h-full w-full;
  @screen lg {
    height: 32vh;
  }
}
</style>
